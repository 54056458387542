<template>
  <div>
    <v-card style="padding: 10px">
      <v-form>
        <v-row>
          <v-col cols="12" md="3">
            <label for="inventory_address">{{ translate("Expense Name") }}</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              id="inventory_name"
              v-model="inventory_name"
              outlined
              dense
              :placeholder="translate('Expense Name')"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="12">
            <v-btn color="primary" v-on:click="submit"> {{ translate("Submit") }} </v-btn>
            <v-btn v-on:click="cancel" type="reset" class="mx-2" outlined>
              {{ translate("Cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import Leaflet from "easy-vue-leaflet";
import axios from "axios";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { Icon } from "leaflet";
import { newExpenseType, newMagazine } from "@/ApiManager";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    leaflet: Leaflet,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      center: [0, 0],
      markerLatLng: [0, 0],
      leaflet_key: 0,
    };
  },
  async created() {
    var coordinates = await this.$getLocation();
    this.center = [coordinates["lat"], coordinates["lng"]];
    this.markerLatLng = [coordinates["lat"], coordinates["lng"]];
    this.leaflet_key += 1;
  },
  methods: {
    mapclick(data) {
      this.markerLatLng = [data.latlng["lat"], data.latlng["lng"]];
    },
    cancel: function (_) {
      this.$router.go(-1);
    },
    submit: function (_) {
      //get the first marker from the list
      var inventoryname = this.inventory_name;
      newExpenseType(inventoryname).then((response) => {
        this.$router.push({ name: "expense_types" });
      });
    },
  },
  setup() {
    const inventory_name = ref("");
    const leaflet_key = ref("");
    const inventory_address = ref("");
    const phone_number = ref("");
    const town = ref("");

    return {
      inventory_name,
      leaflet_key,
      inventory_address,
      phone_number,
      town,
    };
  },
};
</script>
<style>
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");
</style>
